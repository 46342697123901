<template>
  <v-theme-provider dark>
    <base-section id="get-involved" class="accent text-center bac-section" space="56">
      <base-icon class="mb-8">
        mdi-home-city-outline
      </base-icon>

      <base-section-heading
        class="get-involved-head"
        color="transparent"
        title="Would You Like to Get Involved?"
      >
        Are you concerned about the impact this construction project will have on our&nbsp;town?<br>Drop us an email
        to get connected and to learn&nbsp;more!
      </base-section-heading>

      <base-btn
        :ripple="false"
        class="pa-1"
        height="auto"
        text
        @click="contactUs()"
      >
        bradford<span style="font-size: 0px;">&nbsp;</span>area<span
          style="font-size: 0px;"
          >&nbsp;</span
        >commission@gmail.com
      </base-btn>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: "GetInvolved",

  provide: {
    heading: { align: "center" }
  },

  methods: {
    contactUs() {
      window.location.href = "mailto:BradfordAreaCommission@gmail.com";
    }
  }
};
</script>

<style lang="scss">
@media screen and (max-width: 479px) {
  .v-application {
    .get-involved-head {
      h1.text-uppercase.text-h4.font-weight-bold.mb-2.text-center.white--text {
        font-size: 1.25em !important;
        line-height: 150%;
      }
      padding: 0 1em;
    }
  }
  button.font-weight-bold.pa-1.v-btn.v-btn--text.v-btn--tile.theme--dark.v-size--x-large.primary--text {
    font-size: 0.9rem;
  }
}
</style>
